<template>
  <b-card title="Forgot Password">
    <b-col cols="12">
      <b-row v-if="verified===false">
        <b-col cols="12">
          <b-form-group
              label="Token"
              description="Token"
            >
              <b-form-input
                v-model="form.token"
                placeholder="Token"
              ></b-form-input>
          </b-form-group>

          <b-form-group
              label="Email"
              description="Input Email"
            >
              <b-form-input v-model="form.email"/>
          </b-form-group>

          <!-- <b-btn @click="forgetPass" class="mb-1; mr-1">
            Send
          </b-btn> -->
          <b-btn @click="resend" variant="success" class="mb-1; mr-1">
            Submit
          </b-btn>
          <!-- <b-btn @click="verify" class="mb-1; mr-1">
            Verify
          </b-btn> -->
        </b-col>
      </b-row>
      <b-row v-if="verified===true">
        <b-col cols="12">
          <p>Your new password is <strong>{{password}}</strong></p>
          <p>Please Change the password immediately once you logged in</p>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
  data() {
    return {
      form: {
        token: '',
        email: ''
        // holidayDate: ''
      },
      password:'a',
      submit:{otp:''},
      verified:false
    }
  },
  methods: {
    forgetPass() {
      axios.post('auth/forgot-password', this.form).then((response) => {
        this.$bvToast.toast(`You have request a forget password ${this.form.name} ${this.form.holidayDate}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        console.log({e})
        this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
    resend() {
      axios.put('auth/forgot-password', this.form).then((response) => {
        console.log(response)
        this.password = response.data.pass
        this.$bvToast.toast(`Token Verified`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.verified=true
        console.log(this.password);
      }).catch((e) => {
        this.$bvToast.toast(`Failed to resend an OTP ${e.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
    verify() {
      let toSend = {email:this.form.email,otp:this.submit.otp}
      axios.post('sales-and-purchase/drcc/verify-otp', toSend).then((response) => {
        console.log(response)
        this.$bvToast.toast(`${response.data.message}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    },
  }
}
</script>
